import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../Components/generic/Button";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import { useNavigate } from "react-router-dom";
import { IoFilter } from "react-icons/io5";

import Spinner from "../../Components/generic/Spinner";
import { ReviewStatusModal } from "../../Components/Review/ReviewStatusModal";
import SchoolSearchBar, {
  SearchBar,
} from "../../Components/ui/SchoolSearchBar";
import { Addreespick } from "../../Components/generic/Addresspick";
import { LocationModal } from "../../Components/generic/AddlocationModal";
import { useLocationHook } from "../../Hooks/useLocationHook";
import { onGetPayments } from "../../services/payment";
import { FaEnvelopesBulk } from "react-icons/fa6";
import BulkmgsModal from "./message/BulkmgsModal";
import SchoolItem from "../../Components/ui/SchoolItem";

const AllSchools = () => {
  const [schools, setschools] = useState([]);
  const [renderedschools, setrenderedschools] = useState([]);
  const [users, setusers] = useState([]);
  const locatioposhook = useLocationHook();

  const [searchValue, setSearchValue] = useState();
  const [searchLGAVal, setsearchLGAVal] = useState("");
  const school_ = useSelector((state) => state.selectedSchool);
  const onserachChange = (e) => {
    setSearchValue(e);
    setrenderedschools(
      schools.filter((i) =>
        i?.schoolname?.toLowerCase()?.includes(e.toLowerCase())
      )
    );
  };
  var authuser = useSelector((state) => state.userStore);

  const navigate = useNavigate();
  const [showrevmodal, setrevmodal] = useState(false);
  const [showbulk, setshowbulk] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  var userlgas = authuser.lgas.split(" , ");
  const [loactionpicker, setlocationpicker] = useState(false);
  const [totalschool, settotalschools] = useState();
  const [psyments, setpayments] = useState([]);
  const [totalusers, settotalusers] = useState();
  const getallschools = async () => {
    setLoading(true);
    var res = await onGetAllSchool();
    //console.log(res,"response")
    setLoading(false);

    settotalschools(Object.keys(res).length);
    var schoolsarray = [];

    for (let item in res) {
    userlgas.map((hh) => { return hh.toLowerCase();   }) .map((ik)=>{
if( res[item]?.address?.toLowerCase().includes(ik)){
  schoolsarray.push(res[item]);
}
    })
      
       
    }

    setschools(schoolsarray);
  };
  const getPayments = async () => {
    var res = await onGetPayments();
    var emptearr = [];
    for (let school in res.payment) {
      console.log(school);
      emptearr.push(school);
    }

    setpayments(emptearr);
  };
  const dispatcher = useDispatch();
  const filterschools = (e) => {
    if (e.target.checked) {
      setschools(schools.filter((i) => psyments.includes(i.schoolname)));
    } else {
      getallschools();
    }
  };

  useEffect(() => {
    setrenderedschools(schools);
  }, [schools]);

  useEffect(() => {
    dispatcher({ type: "set-pagetitle", payload: "All Schools" });

    setrenderedschools(
      schools.filter((i) =>
        i.address.toLowerCase().includes(address.toLowerCase())
      )
    );
  }, [address]);
  useEffect(() => {
    const init = async () => {
      await getPayments();
      await getallschools();
    };

    init();
  }, []);
  return (
    <div className="">
      <Spinner loading={loading} />

      {showbulk && (
        <BulkmgsModal schools={renderedschools} show={setshowbulk} />
      )}

      {showrevmodal && <ReviewStatusModal setrevmodal={setrevmodal} />}
      {loactionpicker && (
        <LocationModal
          setAddress={setAddress}
          Hook={locatioposhook}
          cancel={setlocationpicker}
        />
      )}
      <div className="bg-customGray flex flex-col p-1 m-1 md:p-4 md:m-4">
        <div className="p-3 bg-white md:flex  items-center mb-4 gap-3">
          {/* <p className="text-[#0000008f]">
            <IoFilter size={20} className="inline text-gray-300 text-sm" /> Filters
          </p> */}
          <SchoolSearchBar
            placeholder="Search by name"
            onchange={onserachChange}
            schools={schools}
            psyments={psyments}
            setrenderedschools={setrenderedschools}
            value={searchValue}
          />
          <div className="p-1 cursor-pointer flex flex-col justify-center items-center bg-gray-50 rounded-lg  ">
            <small className=" text-[10px] ">Results</small>

            <p className="font-semibold  ">{renderedschools.length}</p>
            <small className=" text-[9px] ">Bulk Msg.</small>

            <FaEnvelopesBulk
              onClick={() => setshowbulk(true)}
              size={35}
              className="text-primary bg-gray-200 p-1 m-1"
            />
          </div>
          {/* <SearchBar
            placeholder="Search by LGA/Address"
            onchange={onsearchLGAVal}
            value={searchLGAVal}
          /> */}

          {/* <Addreespick
            cord={address}
            onclick={() => {
              setlocationpicker(true);
            }}
            title="Search by address"
          /> */}

          {/* payment filter
          <p className="m-2 font-light text-sm">
            <span className="p-2 rounded bg-faint inline-block  outline-0">
              <input name="payment" onChange={filterschools} type="checkbox" />
            </span>
            Payment made
          </p> */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 px-6 gap-6">
          {renderedschools.map((item) => (
            <SchoolItem setrevmodal={setrevmodal} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export { AllSchools };
