import { useEffect, useState } from "react";
import Spinner from "../../Components/generic/Spinner";
import { SearchBar } from "../../Components/ui/SchoolSearchBar";
import { FaEnvelopesBulk } from "react-icons/fa6";
import BulkmgsModal from "./message/BulkmgsModal";
import SimpleSearch from "../../Components/ui/SimpleSearch";
import { onGetAllstaffs } from "../../services/user";
import StaffItem from "../../Components/ui/StaffItem";
import { useDispatch } from "react-redux";
import BulkmgsModalStaffs from "./message/BulkmgsModalStaffs";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import { useSelector } from "react-redux";

export default () => {
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [showbulk, setshowbulk] = useState(false);
  const [schools, setschools] = useState([]);
  var authuser = useSelector((state) => state.userStore);
  const [staffs, setstaff] = useState([]);
  const [renderedstaffs, setrenderedstaffs] = useState([]);
  const dispatcher = useDispatch();
  var userlgas = authuser.lgas.split(" , ");
  const getallschools = async () => {
    setLoading(true);
    var res = await onGetAllSchool();
    //console.log(res,"response")
    setLoading(false);

    var schoolsarray = [];
    for (let item in res) {
      userlgas
        .map((hh) => {
          return hh.toLowerCase();
        })
        .map((ik) => {
          if (res[item]?.address?.toLowerCase().includes(ik)) {
            schoolsarray.push(item);
          }
        });
    }

    setschools(schoolsarray);
  };
  const getallStaffs = async () => {
    let emptarr = [];
    setLoading(true);
    var res = await onGetAllstaffs();
    setLoading(false);

    for (let staff in res) {
      emptarr.push(res[staff]);
    }
    setstaff(emptarr);
  };

  const onserachChange = (e) => {
    setSearchValue(e);
    setrenderedstaffs(
      staffs.filter(
        (i) =>
          i.schhool.toLowerCase().includes(e.toLowerCase()) ||
          `${i.firstname} ${i.lastname}`.toLowerCase().includes(e.toLowerCase())
      )
    );
  };

  useEffect(() => {
    const init = async () => {
      dispatcher({ type: "set-pagetitle", payload: "All Staffs" });

      await getallStaffs();

      await getallschools();
    };

    init()
  }, []);

  useEffect(() => {
    setrenderedstaffs(staffs);
  }, [staffs]);

  useEffect(() => {
    setrenderedstaffs(staffs.filter((lk) => schools.includes(lk.schhool)));
  }, [schools]);
  return (
    <div>
      <Spinner loading={loading} />
      {showbulk && (
        <BulkmgsModalStaffs staffs={renderedstaffs} show={setshowbulk} />
      )}
      <div className="bg-customGray flex flex-col p-1 m-1 md:p-4 md:m-4">
        <div className="p-3 bg-white md:flex  items-center mb-4 gap-3">
          <SimpleSearch
            placeholder="Search by school or staff name"
            onchange={onserachChange}
            value={searchValue}
          />
          <div className="p-1 cursor-pointer flex flex-col justify-center items-center bg-gray-50 rounded-lg  ">
            <small className=" text-[10px] ">Results</small>
            <p className="font-semibold  ">{renderedstaffs.length}</p>
            <small className=" text-[9px] ">Bulk Msg.</small>

            <FaEnvelopesBulk
              onClick={() => setshowbulk(true)}
              size={35}
              className="text-primary bg-gray-200 p-1 m-1"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 px-6 gap-6">
          {renderedstaffs.map((item) => (
            <StaffItem item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};
