import { MdDashboard } from "react-icons/md";
import { IoIosSettings } from "react-icons/io";
import { CiReceipt } from "react-icons/ci";
import { FaCertificate, FaComment, FaUser } from "react-icons/fa";
import { MdOutlinePayment } from "react-icons/md";
import { FcRating } from "react-icons/fc";
import { FaMapLocation } from "react-icons/fa6";
import { BiMessage, BiSolidInbox, BiSolidSchool } from "react-icons/bi";
import { FcProcess } from "react-icons/fc";
import { FaSchoolCircleCheck } from "react-icons/fa6";
import { FaSchoolCircleExclamation } from "react-icons/fa6";




const itemstyle = { size: 15, color: "#fff" };
const categories = [
  { name: "Nusery", id: "nusery" },

  { name: "Primary", id: "pprimary" },
  { name: "Secondary", id: "secondary" },
];
const roles = [
  {
    name: "Principal",
    roleid: "Principal",
  },
  {
    name: "Teacher",
    roleid: "Teacher",
  },
  {
    name: "Proprietor",
    roleid: "Proprietor",
  },
  {
    name: "Headmaster/Headmistress",
    roleid: "Headmaster/Headmistress",
  },
];


const zones = [
  {
    name: "IWO",
    lgas: ["IREWOLE", "OLA-OLUWA", "ISOKAN", "AYEDIRE", "IWO", "AYEDAADE"],
  },
  {
    name: "OSOGBO",
    lgas: ["OSOGBO", "OROLU", "OLORUNDA", "IREPODUN"],
  },

  {
    name: "IFE",
    lgas: ["IFE CENTRAL", "IFE EAST", "IFE EAST AREA OFFICE", "IFE SOUTH","IFE NORTH"],
  },
  {
    name: "EDE",
    lgas: ["EDE NORTH", "EDE SOUTH", "EJIGBO", "EGBEDORE"],
  },
  {
    name: "IKIRUN",
    lgas: ["IFELODUN", "IFEDAYO", "BORIPE", "ODO-OTIN","BOLUWADURO","ILA"],
  },
  {
    name: "ILESA",
    lgas: ["ATAKUMOSA EAST", "ATAKUMOSA WEST", "OBOKUN", "ORIADE","ILESA EAST","ILESA WEST"],
  },
];

const States = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT - Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];
const LGAs = [
  "Atakumosa West",
  "Atakumosa East",
  "Ayedaade",
  "Ayedire",
  "Boluwaduro",
  "Boripe",
  "Ede South",
  "Ede North",
  "Egbedore",
  "Ejigbo",
  "Ife North",
  "Ife South",
  "Ife-Central",
  "Ife-East",
  "Ifelodun",
  "Ila",
  "Ilesa-East",
  "Ilesa-West",
  "Ifedayo",
  "Ife-East area office",
  "Irepodun",
  "Irewole",
  "Isokan",
  "Iwo",
  "Obokun",
  "Odo-Otin",
  "Ola Oluwa",
  "Olorunda",
  "Oriade",
  "Orolu",
  "Osogbo",
];

const schoolfacilitycategory = [
  { name: "Classroom", type: "classroom" },

  { name: "Playground", type: "playground" },
  { name: "Sport Facility", type: "sports" },
  { name: "Labouratory", type: "lab" },
  { name: "Mosque", type: "mosque" },
  { name: "Chapel", type: "chapel" },
  { name: "ICT", type: "ict" },
  { name: "Boarding Facility", type: "boarding" },
];



const paymentItems =[{

  title:"Quality Assurance Assessment Fee",
  dueDate:new Date().toDateString(),
  nextDueDate:new Date().toDateString(),

}]
const schooladminitems = [
  {title:'Certificates', path:'/dashboard/certificates'}
,   {title:'Fees', path:'/dashboard/fees'},
{title:'Facilities', path:'/dashboard/facilities'},
{title:'Payments', path:'/dashboard/payments'},
{title:'Map location', path:'/dashboard/mapadmin'}
];

const sidebaritems = [
  {
    name: "Profile",
    path: "/dashboard",
    icon: (
      <MdDashboard
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },

  {
    name: "All Schools",
    path: "/dashboard/allschools",
    icon: (
      <BiSolidSchool 

        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
  {
    name: "Staff",
    path: "/dashboard/staffs",
    icon: (
      <FaUser 
        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),},
  
  {
    name: "Govt. Update",
    path: "/dashboard/messages",
    icon: (
      <BiMessage 

        className="inline"
        size={itemstyle.size}
        color={itemstyle.color}
      />
    ),
  },
 
 
  // {
  //   name: "Ongoing Review",
  //   path: "/dashboard/ongoing",
  //   icon: (
  //     <FaSchoolCircleExclamation
  //       className="inline"
  //       size={itemstyle.size}
  //       color={itemstyle.color}
  //     />
  //   ),
  // },
  // {
  //   name: "Reviewed by me",
  //   path: "/dashboard/reviewed",
  //    icon: (
  //     <FaSchoolCircleCheck
  //       className="inline"
  //       size={itemstyle.size}
  //       color={itemstyle.color}
  //     />
  //   ),
  // }
   
  
];

export {
  sidebaritems,
  States,
  LGAs,
  roles,
  zones,
  categories,
  paymentItems,
  schooladminitems,
  schoolfacilitycategory,
};


  