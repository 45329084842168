import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa"
import { schooladminitems } from "../../constants/data"
import Button from "../generic/Button"
import { SchoolNavItems } from "./SchoolNavItems"
import { SelectedHero } from "./SelectedHero"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { onExportschooldata, onGetProprietor, onGetSchoolClasses } from "../../services/school"
import Spinner from "../generic/Spinner"
import { onGetAllstaffs, onGetUser } from "../../services/user"
import { toast } from "react-toastify"
import { MdDelete, MdLock, MdSend } from "react-icons/md"
import DeleteSchoolModal from "./DeleteSchoolModal"
import LockSchoolModal from "./LockSchoolModal"

const    SelectedSchool=( )=>{
  const school_=useSelector((state)=>state.selectedSchool)
  const [loading, setLoading] = useState(false);
  const [totalstudents, settotalstudents] = useState(0);
  const [propsdetails, setpropsdetails] = useState({});
  const [userdetails, setuserdetails] = useState({});
  const [schoolClasses, setschoolClasses] = useState([]);
  const [allstaffs, setallstaffs] = useState([]);
  const userschool=useSelector((state)=>state.selectedSchool)
  const [showedelete, setshowedelete] = useState(false);
  const [showelock, setshowlock] = useState(false);
  const dispatcher =useDispatch()
const navigate =useNavigate()
useEffect(()=>{
  if(school_.schoolname==undefined){
    navigate('/allschools')
   return
   }
},[])
    
const getSchoolClasses = async () => {
  let postData = {
    schoolname: userschool.schoolname,
  };

  setLoading(true);
  var response = await onGetSchoolClasses(postData);
  setLoading(false);
  var emptyArr = [];
  let totalstudents_ = 0;
  Object.keys(response).map((key) => {
    emptyArr.push(response[key]);

    totalstudents_ =
      parseInt(totalstudents_) +
      (parseInt(response[key].male) + parseInt(response[key].female));
    console.log(
      parseInt(response[key].male) + parseInt(response[key].female),
      "kkk"
    );
  });

  settotalstudents(totalstudents_);

  setschoolClasses(
    emptyArr.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    })
  );
};

const onGetPropDet = async () => {
  let postdata = { schoolname: userschool.schoolname };
  try {
    setLoading(true);
    var response = await onGetProprietor(postdata);
    setLoading(false);
    setpropsdetails(response.details);
  } catch (e) {
    setLoading(false);

    console.log(e);
    toast.error(e); 
  }
  setLoading(false);
};



const getUserdetails=async()=>{
  let postdata = { email: userschool.user };
  try {
    setLoading(true);
    var response = await onGetUser(postdata);
    setLoading(false);
    setuserdetails(response);
  } catch (e) {
    setLoading(false);

    console.log(e);
    toast.error(e); 
  }
  setLoading(false);

}
const getallstaffs = async () => {
  setLoading(true);
  var response = await onGetAllstaffs();
  setLoading(false);

  var emptyArr = [];
  Object.keys(response).map((key) => {
    if (response[key].schhool == userschool.schoolname)
      emptyArr.push(response[key]);
  });

  setallstaffs(emptyArr);
  console.log(emptyArr);
};


useEffect(()=>{
const int= async ()=>{

 await  getSchoolClasses()
 await  getallstaffs()
 await  onGetPropDet()
 await getUserdetails()
}
int()

},[])
const Export=async ()=>{

  setLoading(true)
let postData={
school:school_.schoolname
}
   var res= await onExportschooldata(postData)
setLoading(false)


}


useEffect(() => {
  document.getElementById('rot').scrollTo(0, 0);
}, []);
useEffect(() => {
  dispatcher({ type: "set-pagetitle", payload: "School dashboard" });

 
}, []);
    return (<>
          <Spinner loading={loading} />

          {showedelete && (
          <DeleteSchoolModal det={propsdetails} show={setshowedelete} />
        )}
          {showelock && (
          <LockSchoolModal det={propsdetails} show={setshowlock} />
        )}
    <SelectedHero propsdetails={propsdetails} std={totalstudents} stf={allstaffs.length}/>
    <FaArrowLeft className=" ml-3 m-3 mb-3 text-primary" size={30} onClick={()=>navigate(-1)}/>
    <div className=" bg-customGray  flex  flex-col md:flex-row items-start md:items-center md:justify-between   text-sm rounded-b-md p-3 ">
          <div className="grid grid-cols-2 ">
            <p className="font-semibold text-lg text-primary">
              <p className=" text-xs font-light">{school_.creatorrole}</p>
              <ul className="font-light truncate">
           
              <li>
                Name:{userdetails.firstname} {userdetails.lastname}
              </li>
              <li>Email:{userdetails.email}</li>
              <li>Phone:{userdetails.phone}</li>
            </ul>
            </p>
          
          </div>
          <div className="  ">
            <p className="font-semibold   text-lg text-primary">
              {"School Proprietor"} 
            </p>
            <ul className="font-light truncate">
              <li>
                Name:{propsdetails.firstname} {propsdetails.lastname}
              </li>
              <li>Email:{propsdetails.email}</li>
              <li>Phone:{propsdetails.phone}</li>
            </ul>
          </div>
        </div>
         <Button
width={150}
outlined
            text={
              <div className="border border-transparent uppercase ">
                {" "}
                <MdSend size={20} className="inline  text-blue-400" /> Send message
              </div>
            }
            onBtnClick={() => {
            navigate('/dashboard/message')
            }}
          /> 
    <div className="bg-customGray flex flex-col p-4 m-4">
        {/* <div className=" place-self-end self-end ">

          <Button text="Export data" onBtnClick={() => {  Export()}} />
        </div> */}
        <div className=" bg-white  flex justify-center flex-col items-center   text-sm rounded-b-md p-3">
      
      <h1 className=" font-semibold text-lg text-primary ">Link to School Website/Social media</h1>
      
      <p className=" text-[15px] p-5"><a  className=" text-blue-400  " href={school_.link}>
        <FaExternalLinkAlt
                className="text-primary inline  mx-2"
                size={20}
              />Visit    </a>  
      
      
       </p>
      </div>

<div className="grid  md:mx-20 md:grid-cols-2  grid-cols-1 m-6 gap-6">
{schooladminitems.map((item)=> (<SchoolNavItems item={item}/>))}
 
 
 <div>
 {/* <Button
             outlined
            text={
              <div className="border border-transparent uppercase ">
                {" "}
                <MdDelete size={30} className="inline  text-rose-400" /> Delete School
              </div>
            }
            onBtnClick={() => {
              setshowedelete(true);
            }}
          /> */}

{/* <Button
             outlined
            text={
              <div className="border border-transparent uppercase ">
                {" "}
                <MdLock size={30} className="inline  text-rose-400" /> {userschool.locked ? "Unlock school" : "Lock school"}
              </div>
            }
            onBtnClick={() => {
              setshowlock(true);
            }}
          /> */}
  
  </div> 
 
</div>
    </div>
    </>)
}
export{SelectedSchool}