import { useForm } from "react-hook-form";
import Button from "../generic/Button";
import InputField from "../generic/Inputfield";
import { Schoolpicker } from "./Schoolpicker";
import { useSchoolPickerhook } from "../../Hooks/useSchoolpickerhook";
import { Rolepicker } from "./RolePicker";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { onGetAllSchool } from "../../Utils/Servicess/school";
import DatePicker from "react-datepicker";
import { MdDateRange } from "react-icons/md";
import { ListPicker } from "../ui/ListPicker";
import { usePickerhook } from "../../Hooks/usePickerhook";
import { LGAs, zones } from "../../constants/data";
import { useCategoryPickerhook } from "../../Hooks/useCategoryPickerhook";
import ListChecker from "../ui/ListChecker";

const StepThree = (props) => {
  const positionhook = usePickerhook();
  const lgapickedhook = usePickerhook();
  const zonepickedhook = usePickerhook();
  const inpectortypehook = usePickerhook();
  const lgapicker = useCategoryPickerhook();

  const [DOB, setDOB] = useState(new Date());
  const [DOE, setDOE] = useState(new Date());
  const [zonesLga, setzonesLga] = useState([]);
  const [allschools, setallschools] = useState([]);
  const { control, handleSubmit } = useForm();
  const positions = [
    "Permanent Secretary (1)",
    "Director of QA (1)",
    "Deputy Directors of QA (2)",
    "Assistant Director (1)",
    "Chief Education Officer (1)",
    "Assistant Chief Education Officer (2)",
    "Principal Education Officer (2)",
    "Senior Education Officer (1)",
  ];

  const inpectorType = [
    "Local Inspector (LIE)",
    "HQ Officers",
    "Zonal Inspector (ZI)",
  ];

  const init = async () => {
    var response = await onGetAllSchool();

    setallschools(Object.keys(response));
  };



  useEffect(()=>{

lgapicker.setselected([])


  },[zonepickedhook.pickedRole, inpectortypehook.pickedRole])

  useEffect(() => {
    var arr = [];

    zones.map((item) => {
      if (item.name == zonepickedhook.pickedRole) {
        item.lgas.map((ku) => {
          arr.push({ name: ku, id: ku });
        });
      }
    });

    setzonesLga(arr);
  }, [zonepickedhook.pickedRole]);
  useEffect(() => {
    init();
  }, []);
  const onSubmit = (data) => {
    if (lgapicker.selected.length==0) {
      {
        toast.error("All fields are required ");
        return;
      }
    }

    props.nextStep();
    props.setCurrentStepIndex(3);

    var postdata = {
      inspectortype: inpectortypehook.pickedRole,
      lgas:  lgapicker.selected.join(" , "),
      doe: DOE.toString(),
      dob: DOB.toString(),
    };

    props.setpostdata({ ...props.postdata, ...postdata });
  };

  return (
    <div className="bg-white">
      <p className="m-3 text-primary font-semibold">Gov. Staff details</p>

      {/* Date of birth */}
      <div className="my-4">
        <p className="text-xs mb-2 ">Date Of Birth</p>
        <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
          <MdDateRange className="p-2 " size={30} />

          <DatePicker
            className="font-light"
            selected={DOB}
            onChange={(date) => setDOB(date)}
          />
        </div>
      </div>
      {/* Date of entry to service */}
      <div className="my-4">
        <p className="text-xs mb-2 ">Date of entry to service</p>
        <div className=" px-4 py-1  flex text-sm bg-gray-50 w-full  rounded   items-center">
          <MdDateRange className="p-2 " size={30} />

          <DatePicker
            className="font-light"
            selected={DOE}
            onChange={(date) => setDOE(date)}
          />
        </div>
      </div>

      <ListPicker
        title="Inspector type"
        hook={inpectortypehook}
        list={inpectorType}
      />
      <ListPicker
        title={"Select Zone"}
        hook={zonepickedhook}
        list={zones.map((i) => {
          return i.name;
        })}
      />
      <ListChecker
        title={"Select LGA"}
        type={
          inpectortypehook.pickedRole == "Local Inspector (LIE)"
            ? "radio"
            : "checkbox"
        }
        list={  zonesLga    }
        hook={lgapicker}
      />

      <div className=" flex gap-4 ">
        <Button
          outlined
          text="Previous"
          onBtnClick={() => {
            props.setCurrentStepIndex(1);
            props.previousStep();
          }}
        />
        <Button text="Next" onBtnClick={handleSubmit(onSubmit)} />
      </div>
    </div>
  );
};

export { StepThree };
